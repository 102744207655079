import React, {useContext, useEffect, useState} from "react";
import {makeAuthenticatedRequest} from "../../Utils/authenticated_request";
import {
    ActionIcon,
    Alert,
    Badge,
    Button,
    Card,
    Center,
    Container,
    Divider,
    Flex,
    Paper,
    rem,
    ScrollArea,
    SimpleGrid,
    Space,
    Text,
    TextInput,
    Tooltip,
} from '@mantine/core';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {IconInfoCircle, IconLock, IconPlus, IconSearch, IconX} from "@tabler/icons-react";
import {UserContext} from "../../Context/User";
import Footer from "../Footer";
import {ParallaxProvider} from "react-scroll-parallax";
import SmallYarn from "../Shapes/smallYarn";
import MediumYarn from "../Shapes/mediumYarn";
import LargeYarn from "../Shapes/largeYarn";


const RowCounterManager = (
    {}) => {
    const navigate = useNavigate();
    const {user} = useContext(UserContext);

    const [rowCounters, setRowCounters] = React.useState([])
    const [rowCounterSearch, setRowCounterSearch] = useState('');
    const [displayedRowCounters, setDisplayedRowCounters] = useState(rowCounters);

    const fetchRowCounters = async () => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/row_counter/`,
                {redirect: false}
            );

            if (response.ok) {
                const jsonData = await response.json();
                setRowCounters(jsonData.results)
            setDisplayedRowCounters(jsonData.results)
        } else {
            console.error("Request failed with status:", response.status);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
    }

    const deleteRowCounter = async (rowCounter) => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/row_counter/${rowCounter.uuid}/`,
                {method: 'DELETE'},
            );

            if (response.ok) {
                await fetchRowCounters()
            } else {
                console.error("Request failed with status:", response.status);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const editRowCounter = (rowCounter, rowCounterIndex) => {
        if (rowCounterIndex >= 100) {
            return
        }
        navigate('/rowcounter/edit/', {
            state: {
                stitch: rowCounter.stitch,
                rows: rowCounter.rows,
                name: rowCounter.name,
                uuid: rowCounter.uuid
            }
        });
    }

    const handleDeleteSearch = () => {
        setRowCounterSearch("")
        setDisplayedRowCounters(rowCounters)
    }

    const handleRowCounterSearch = (event) => {
        setRowCounterSearch(event.currentTarget.value)


        const filteredRowCounters = rowCounters.filter((rowCounter) =>
            rowCounter.name.toLowerCase().includes(event.currentTarget.value.toLowerCase())
        );

        if (filteredRowCounters !== displayedRowCounters) {
            setDisplayedRowCounters(filteredRowCounters);
        }
    };

    useEffect(() => {
        fetchRowCounters()
    }, []);

    return (
        <React.Fragment>
            <Space mt={"10vh"}/>

            <ParallaxProvider>
                <SmallYarn/>
                <MediumYarn/>
                <LargeYarn/>
            </ParallaxProvider>
            <Container size={"80%"}>

                {rowCounters.length >= 100 ? (<Tooltip
                    label={"Max 100 allowed, please delete some items"}
                >
                    <Button p={20} disabled fullWidth size="xl" leftSection={<IconPlus size={20}/>}>
                        Create New
                    </Button>
                </Tooltip>) : (<Button p={20} disabled={rowCounters.length > 100} fullWidth size="xl"
                                       leftSection={<IconPlus size={20}/>}
                                       component={RouterLink}
                                               to="create">Create New</Button>)}
                        <TextInput
                            mt={"md"}
                            leftSection={<IconSearch style={{width: rem(16), height: rem(16)}}/>}
                            leftSectionPointerEvents="none"
                            rightSection={rowCounterSearch ? <IconX onClick={handleDeleteSearch} style={{
                                width: rem(16),
                                height: rem(16)
                            }}/> : null}
                            aria-label="Search Row Counters"
                            value={rowCounterSearch}
                            onChange={(event) => handleRowCounterSearch(event)}
                        />
                {user?.tier !== "PREMIUM" &&
                    <Alert title="Premium required to save" icon={<IconInfoCircle/>}>
                        You're not signed in as a premium member. You can create row counters, but you won't be able to
                        save any counter that you create.
                    </Alert>
                }
                <Paper shadow="xl" p="xl" m="md" withBorder>
                    <ScrollArea h={'55vh'}>
                        <SimpleGrid
                            cols={{base: 1, sm: 2, md: 3, lg: 4}}
                            spacing={{base: 10, sm: 'xl'}}
                            verticalSpacing={{base: 'md', sm: 'xl'}}
                        >
                            {displayedRowCounters.map((rowCounter, rowCounterIndex) => {
                                return (
                                    <Card
                                        key={rowCounter.uuid}
                                        shadow="sm"
                                        padding="xs"
                                        radius="md"
                                        withBorder
                                        onClick={() => editRowCounter(rowCounter, rowCounterIndex)}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                    >
                                        <Flex
                                            justify="flex-end"
                                            align="flex-start"
                                            direction="row"
                                            wrap="wrap"
                                        >
                                            <Tooltip label="Delete Row Counter">
                                                <ActionIcon
                                                    variant="outline"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        deleteRowCounter(rowCounter)
                                                    }}
                                                >
                                                    <IconX size={20}/>
                                                </ActionIcon>
                                            </Tooltip>
                                        </Flex>
                                        <Center>
                                            <Text
                                                size="xl"
                                                fw={900}
                                                variant={"gradient"}
                                                truncate="end"
                                            >
                                                {(rowCounterIndex >= 100) &&
                                                    <Tooltip
                                                        label={"Max 100 allowed, please delete some items"}><IconLock/></Tooltip>}
                                                {rowCounter.name}
                                            </Text>
                                        </Center>

                                        <Divider my={"xs"}/>
                                        <Card.Section p={"sm"}>
                                            <Center>
                                                <Text>Stitch <Badge>{rowCounter.stitch + 1}</Badge></Text>
                                            </Center>
                                            <Center>
                                                <Text>Row <Badge>{rowCounter.rows.length}</Badge></Text>
                                            </Center>
                                        </Card.Section>
                                    </Card>
                                )
                            })}
                        </SimpleGrid>
                    </ScrollArea>
                </Paper>
            </Container>
            <Footer/>
        </React.Fragment>
    )

}

export default RowCounterManager;