import React from 'react';
import {Outlet} from "react-router-dom";


const PatternBuilderShell = () => {

    return (
    <Outlet/>
    );
}

export default PatternBuilderShell;
