import React from 'react';
import {Blockquote, Center, Divider, Paper, SegmentedControl, SemiCircleProgress, Tabs, Tooltip} from "@mantine/core";
import {IconInfoCircle, IconRosette} from "@tabler/icons-react";


const PatternBuilderDesign = (
    {
        activeDesignTab,
        setActiveDesignTab,
        selectedItem,
        designTabs,
        updateDesignTabs,
        difficulty,
    }
) => {

    return (
<React.Fragment>
                                <Tabs
                                    value={activeDesignTab}
                                    onChange={setActiveDesignTab}
                                    color="violet"
                                    variant="pills"
                                    radius="lg"
                                >
                                    <Tabs.List grow>
                                        {selectedItem?.parts.map((part) => {
                                            return (
                                                <Tabs.Tab key={part.uuid} value={part.name}>
                                                    {part.name}
                                                </Tabs.Tab>
                                            )
                                        })}
                                    </Tabs.List>
                                    <Divider my={"md"}/>
                                    {selectedItem?.parts.map((part) => {
                                        return (
                                            <Tabs.Panel key={part.uuid} value={part.name}>
                                                <Center>
                                                    <SegmentedControl
                                                        orientation="vertical"
                                                        value={designTabs[part.name]}
                                                        onChange={(e) => updateDesignTabs(part.name, e, part)}
                                                        color={"violet"}
                                                        data={part.customisation_items.map((customisation_item) => {
                                                            return (
                                                                {
                                                                    label: (
                                                                        <Center key={customisation_item.uuid} style={{gap: 10}}>
                                                                            <span>{customisation_item.name}</span>
                                                                            {customisation_item.price !== "0.00" &&
                                                                                <span>€{customisation_item.price}</span>}
                                                                            {customisation_item.is_premium &&
                                                                                <Tooltip label="Premium Required">
                                                                                    <IconRosette size={20}/>
                                                                                </Tooltip>
                                                                            }
                                                                        </Center>
                                                                    ),
                                                                    value: customisation_item.name,
                                                                }
                                                            )
                                                        })}
                                                    />
                                                </Center>
                                            </Tabs.Panel>
                                        )
                                    })}
                                </Tabs>
                                {selectedItem.parts.length === 0 &&
                                    <Paper shadow="xl">
                                        <Blockquote icon={<IconInfoCircle/>} color={"violet"}>
                                            This item does not have any options to change its appearance.
                                            However, you can still select your size and input your gauge swatch in
                                            the next steps!
                                        </Blockquote>
                                    </Paper>
                                }
                                <Divider my={"md"}/>
                                <Center>
                                    <SemiCircleProgress
                                        fillDirection="left-to-right"
                                        orientation="up"
                                        size={200}
                                        thickness={20}
                                        value={difficulty}
                                        label="Difficulty"
                                    />
                                </Center>
                            </React.Fragment>
    );
}

export default PatternBuilderDesign;
