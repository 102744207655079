import React from 'react';
import {Badge, Button, Center, Divider, SemiCircleProgress, Text} from "@mantine/core";


const PatternBuilderSummary = (
    {
        designTabs,
        selectedStitch,
        selectedSize,
        gaugeStitches,
        gaugeRows,
        price,
        difficulty,
        confirmPatternBuilder,
    }
    ) => {

    return (
                                <React.Fragment>
                                    <Text>Design</Text>
                                    {
                                        designTabs.length !== 0 &&
                                        <Badge variant="light" key="defaultDesign">default design</Badge>
                                    }
                                    {Object.values(designTabs).map((designTab) => {
                                        return (
                                            <Badge key={designTab} variant="light">
                                                {designTab}
                                            </Badge>
                                        )
                                    })}
                                    <Divider my={"md"}/>
                                    {/*<Text>*/}
                                    {/*    Stitch*/}
                                    {/*</Text>*/}
                                    {/*<Badge variant="light">*/}
                                    {/*    {selectedStitch.name}*/}
                                    {/*</Badge>*/}
                                    {/*<Divider my={"md"}/>*/}
                                    <Text>
                                        Size
                                    </Text>
                                    <Badge variant="light">
                                        {selectedSize.size}
                                    </Badge>
                                    <Divider my={"md"}/>
                                    <Text>
                                        Gauge Swatch
                                    </Text>
                                    <Badge variant="light">
                                        {gaugeStitches} Stitches
                                    </Badge>
                                    <Badge variant="light">
                                        {gaugeRows} Rows
                                    </Badge>
                                    <Divider my={"md"}/>
                                    <Text>
                                        Price
                                    </Text>
                                    <Badge variant="light">
                                        €{price}
                                    </Badge>
                                    <Divider my={"md"}/>
                                    <Center>
                                        <SemiCircleProgress
                                            fillDirection="left-to-right"
                                            orientation="up"
                                            size={200}
                                            thickness={20}
                                            value={difficulty}
                                            label="Difficulty"
                                        />
                                    </Center>
                                    <Divider my={"md"}/>
                                    <Center>
                                        <Button onClick={confirmPatternBuilder}>Confirm</Button>
                                    </Center>
                                </React.Fragment>
    );
}

export default PatternBuilderSummary;
