import React from 'react';
import {
    Anchor,
    Blockquote,
    Container,
    Flex,
    getThemeColor,
    Image,
    List,
    Paper,
    rem,
    Space,
    Stack,
    Text,
    ThemeIcon,
    Title,
    useMantineTheme
} from "@mantine/core";
import {ParallaxProvider} from "react-scroll-parallax";
import SmallYarn from "../Shapes/smallYarn";
import MediumYarn from "../Shapes/mediumYarn";
import LargeYarn from "../Shapes/largeYarn";
import {IconCircleCheck} from "@tabler/icons-react";

const TutorialsGaugeSwatch = () => {
    const theme = useMantineTheme()

    return (
        <React.Fragment>
            <Space h="10vh"/>
            <ParallaxProvider>
                <SmallYarn/>
                <MediumYarn/>
                <LargeYarn/>
            </ParallaxProvider>
            <Flex>
                <Container style={{flex: 1}}>
                    <Stack
                        spacing="xl"
                        c="white"
                        p={"md"}
                        style={{
                            backgroundColor: getThemeColor('violet', theme),
                        }}
                    >
                        <Title align="center" order={1} size="h1" p={"xl"}>
                            Gauge Swatch
                        </Title>

                        <Text fs="italic" align="center" size="lg" p={"xl"} fw={500}>
                            What is it, and how to create one yourself
                        </Text>
                    </Stack>
                </Container>
                <Container style={{flex: 1}}>
                    <Image
                        style={{
                            objectFit: "cover" // Keeps image aspect ratio intact
                        }}
                        src={"/gaugeswatch.png"}
                    />
                </Container>
            </Flex>
            <Blockquote color={"violet"}>
                <Paper shadow="xl">
                <Blockquote color={"violet"}>
                    There are many elements that can makes each crochet stitch unique in its size and shape.
                    This means we need to standardise exactly how many stitches and rows it takes to cover a
                    certain area, otherwise the projects that we create will be entirely different sizes.
                    For example, some of these factors include:
                                                                                <List
                                    p={"xl"}
                                    spacing="md"
                                    size="md"
                                    center
                                    icon={
                                        <ThemeIcon color="violet" size={24} radius="xl">
                                            <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                        </ThemeIcon>
                                    }
                                >
<List.Item>
      <Paper shadow="xl" p="xs">
      <Text inline fw={1000} variant="gradient">
        Hook
      </Text>
      <Text fw={500} inline>The size and shape of the hook you're using</Text>
      </Paper>
</List.Item>
                                                                        <List.Item>
  <Paper shadow="xl" p="xs">
      <Text inline fw={1000} variant="gradient">
        Yarn
      </Text>
      <Text fw={500} inline>The weight, ply, and material properties of the yarn you're working with</Text>
  </Paper>
</List.Item>
                                                                        <List.Item>
  <Paper shadow="xl" p="xs">
      <Text inline fw={1000} variant="gradient">
        Tension
      </Text>
      <Text fw={500} inline>Your individual crocheting style and how tight you like to make your stitches</Text>
  </Paper>
</List.Item>
                                </List>
                                    </Blockquote>
                    </Paper>
                                                <br/>

            <Paper shadow="xl">
                <Blockquote color={"violet"}>
                    This is where the gauge swatch comes in. It's a small square of a particular size
                    that you will need to crochet and then count the stitches and rows that went into making it.
                    You should typically use the same hook and yarn that you'll be using for the full project in order
                    to keep everything consistent.
                    The types of stitches that you will use to create a gauge swatch often depends on the project.
                    It's common to measure the interior of the gauge swatch, because the outer few rows will behave a
                    bit inconsistently when compared to the inner rows.
                </Blockquote>
            </Paper>
                <br/>
                <Paper shadow="xl">
                <Blockquote color={"violet"}>
                    For example, if you were asked to create a gauge swatch of 6 inches (15cm), you would then measure
                    out and count the stitches and rows of the inner 4 inches (10cm),
                    ignoring the 1 inch border (2.5 cm) on each side.
                    If you plan on blocking and/or washing your finished project, then you should also ideally do the
                    same to the gauge swatch prior to measuring.
                </Blockquote>
            </Paper>
                                <br/>
                <Paper shadow="xl">
                <Blockquote color={"violet"}>
                    When using our <Anchor href="/patternbuilder/create" target="_blank">pattern builder</Anchor>, you will be asked to create a gauge swatch so our patterns can be
                    as accurate as possible for you.
                    In this case, you will be asked to make a gauge swatch out of your selected stitch.
                    Let's take a single crochet, for example.
                    It all starts with a row of foundation single crochets or chains. When you reach the required length,
                    you can start your first row by turning and creating a single crochet in each stitch.
                    Keep repeating this until you have a square.
                    Once you've made the square, count the stitches and rows within the specified measurements.
                    This will be your gauge, use it to fill in the fields in the pattern builder and we'll
                    give you a custom pattern to follow!
                </Blockquote>
            </Paper>
                            </Blockquote>


        </React.Fragment>
    );
}

export default TutorialsGaugeSwatch;
