import React from 'react';
import {
    AspectRatio,
    Blockquote,
    Button,
    Center,
    Container,
    Flex,
    getThemeColor,
    Image,
    List,
    Paper,
    rem,
    Space,
    Stack,
    Text,
    ThemeIcon,
    Title,
    useMantineTheme
} from "@mantine/core";
import {ParallaxProvider} from "react-scroll-parallax";
import SmallYarn from "../Shapes/smallYarn";
import MediumYarn from "../Shapes/mediumYarn";
import LargeYarn from "../Shapes/largeYarn";
import {IconCircleCheck} from "@tabler/icons-react";

const Hiring = () => {
    const theme = useMantineTheme()

    return (
        <React.Fragment>
            <Space h="10vh"/>
            <ParallaxProvider>
                <SmallYarn/>
                <MediumYarn/>
                <LargeYarn/>
            </ParallaxProvider>
            <Flex>
                <Container style={{flex: 1}}>
                    <Stack
                        spacing="xl"
                        c="white"
                        p={"md"}
                        style={{
                            backgroundColor: getThemeColor('violet', theme),
                        }}
                    >
                        <Title align="center" order={1} size="h1" p={"xl"}>
                            We're Hiring!
                        </Title>

                        <Text fs="italic" align="center" size="lg" p={"xl"} fw={500}>
                            Pattern Designer and Tester
                        </Text>
                    </Stack>
                </Container>
                <Container style={{flex: 1}}>
                    <Image
                        style={{
                            objectFit: "cover" // Keeps image aspect ratio intact
                        }}
                        src={"/hiring.png"}
                    />
                </Container>
            </Flex>
            <Blockquote color={"violet"}>
                <Paper shadow="xl">
                    <Blockquote color={"violet"}>
                        CrochetCrafters.com is an online platform that helps crocheters generate patterns, track their
                        progress, and bring their crochet projects to life. The website already has a loyal following
                        that’s growing every day. Our main features include:
                        <List
                            p={"xl"}
                            spacing="md"
                            size="md"
                            center
                            icon={
                                <ThemeIcon color="violet" size={24} radius="xl">
                                    <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                </ThemeIcon>
                            }
                        >
                            <List.Item>
                                <Paper shadow="xl" p="xs">
                                    <Text inline fw={1000} variant="gradient">
                                        Grid Pattern Generator
                                    </Text>
                                    <Text fw={500} inline>Easily create grid crochet patterns from images.</Text>
                                </Paper>
                            </List.Item>
                            <List.Item>
                                <Paper shadow="xl" p="xs">
                                    <Text inline fw={1000} variant="gradient">
                                        Symbol Pattern Generator
                                    </Text>
                                    <Text fw={500} inline>Automatically generate symbol patterns for more complex
                                        designs.</Text>
                                </Paper>
                            </List.Item>
                            <List.Item>
                                <Paper shadow="xl" p="xs">
                                    <Text inline fw={1000} variant="gradient">
                                        Row Counter
                                    </Text>
                                    <Text fw={500} inline>A built-in row counter to help keep track of progress.</Text>
                                </Paper>
                            </List.Item>
                        </List>
                        But we’re not stopping there. We’re now working on the next big feature: a 3D Clothing Creator
                        Tool that lets users customise their designs and get a dynamically generated crochet pattern
                        with detailed instructions.

                    </Blockquote>
                </Paper>
                <br/>
                <Paper shadow="xl">
                    <Blockquote color={"violet"}>
                        <Title align="center" order={2} size="h2" p={"xl"}>
                            The Clothing Creator Tool
                        </Title>
                        With this tool, users will be able to:
                        <List
                            p={"xl"}
                            spacing="md"
                            size="md"
                            center
                            icon={
                                <ThemeIcon color="violet" size={24} radius="xl">
                                    <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                </ThemeIcon>
                            }
                        >
                            <List.Item>
                                <Paper shadow="xl" p="xs">
                                    <Text fw={500} inline>Choose from pre-designed templates for different clothing
                                        items (jumpers, cardigans, tops,
                                        pants, hats, etc)</Text>
                                </Paper>
                            </List.Item>
                            <List.Item>
                                <Paper shadow="xl" p="xs">
                                    <Text fw={500} inline>Customise these items with different options (neckline style,
                                        sleeve length, cropped length, etc.)</Text>
                                </Paper>
                            </List.Item>
                            <List.Item>
                                <Paper shadow="xl" p="xs">
                                    <Text fw={500} inline>Input their size and gauge measurements to get a perfectly
                                        tailored crochet pattern.</Text>
                                </Paper>
                            </List.Item>
                            <List.Item>
                                <Paper shadow="xl" p="xs">
                                    <Text fw={500} inline>View their design in 3D, with instant updates as they change
                                        the parameters.</Text>
                                </Paper>
                            </List.Item>
                            <List.Item>
                                <Paper shadow="xl" p="xs">
                                    <Text fw={500} inline>Receive a dynamically generated PDF with detailed crochet
                                        instructions, all based on their
                                        unique design choices.</Text>
                                </Paper>
                            </List.Item>
                        </List>
                        We aim to revolutionise the way crocheters approach clothing design, giving them the freedom to
                        create anything they want, with a perfect fit. If you’re interested, you can see a basic demo of
                        the tool below (note that the models and generated patterns are simply placeholders).
                        <Space mt={"5vh"}/>
                        <AspectRatio ratio={16 / 9}>

                            <iframe
                                width="50%"
                                height="auto"
                                src="https://www.youtube.com/embed/37KgZj6a320"
                                title="CrochetCrafters.com - Clothing Builder Demo"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </AspectRatio>
                    </Blockquote>
                </Paper>
                <br/>
                <Paper shadow="xl">
                    <Blockquote color={"violet"}>
                        <Title align="center" order={2} size="h2" p={"xl"}>
                            Your Role
                        </Title>
                        We’re looking for an experienced crocheter to join us as a pattern designer and tester. Your
                        main responsibilities will include:
                        <List
                            p={"xl"}
                            spacing="md"
                            size="md"
                            center
                            icon={
                                <ThemeIcon color="violet" size={24} radius="xl">
                                    <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                </ThemeIcon>
                            }
                        >
                            <List.Item>
                                <Paper shadow="xl" p="xs">
                                    <Text fw={500} inline>Designing base crochet patterns for various clothing items
                                        (jumpers, cardigans, etc.)</Text>
                                </Paper>
                            </List.Item>
                            <List.Item>
                                <Paper shadow="xl" p="xs">
                                    <Text fw={500} inline>Testing the patterns to ensure they work correctly when
                                        adjusted for different sizes and gauges.</Text>
                                </Paper>
                            </List.Item>
                            <List.Item>
                                <Paper shadow="xl" p="xs">
                                    <Text fw={500} inline>Providing feedback on the dynamic pattern generation to help
                                        fine-tune the algorithm.</Text>
                                </Paper>
                            </List.Item>
                        </List>
                        Additionally, we’re offering extra incentives for creating content to help promote the tool via
                        creating a couple of short videos (or photos) demonstrating how the tool
                        works and then displaying the crocheted result. These videos will be featured on our Instagram
                        and other platforms to help spread the word.
                    </Blockquote>
                </Paper>
                <br/>
                <Paper shadow="xl">
                    <Blockquote color={"violet"}>
                        <Title align="center" order={2} size="h2" p={"xl"}>
                            Our Role
                        </Title>
                        We designed the tool and have everything ready to go for a talented pattern designer to take the
                        wheel. We also have a growing community that already uses our website to create their own
                        patterns. We’ll keep developing and improving our technology, and making changes based on your
                        (and the customers’) feedback. We’ll also be fronting advertising costs, website upkeep costs,
                        and any other costs associated with this project.
                    </Blockquote>
                </Paper>
                <br/>
                <Paper shadow="xl">
                    <Blockquote color={"violet"}>
                        <Title align="center" order={2} size="h2" p={"xl"}>
                            Compensation and Revenue Share
                        </Title>
                        We’re offering a <b>25% revenue share</b> from the sales of the tool. This means you’ll earn a
                        percentage of every pattern purchase made through the platform. We’re offering an <b>additional
                        5% revenue
                        share</b> if you contribute social media content (such as creating a couple of videos
                        demonstrating
                        the tool’s features). Your content will be featured on our official Instagram account and other
                        platforms.

                        This partnership gives you the chance to be at the forefront of
                        a game-changing tool that combines modern tech with traditional crochet. You’ll have the
                        opportunity to shape the product from the ground up, making your mark on the crochet world.
                        Plus, as the platform grows, so will your revenue share.
                    </Blockquote>
                </Paper>
                <br/>
                <Paper shadow="xl">
                    <Blockquote color={"violet"}>
                        <Title align="center" order={2} size="h2" p={"xl"}>
                            What's next?
                        </Title>
                        Join Us! If this sounds like the perfect opportunity for you, we’d love to hear
                        from you! Please reach out to us via <b>hello@crochetcrafters.com</b>. If you have any previous
                        crochet
                        patterns or videos, feel free to share them with us to showcase your work.
                        We look forward to potentially working together to bring this exciting tool to the crochet
                        community!
                    </Blockquote>
                </Paper>

                <Space mt={"5vh"}/>

                <Center>
                    <Button component="a" href="mailto:hello@crochetcrafters.com" size="xl">
                        Apply Now
                    </Button>
                </Center>
            </Blockquote>


        </React.Fragment>
    );
}

export default Hiring;
