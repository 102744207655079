import React, {Suspense, useContext, useEffect, useState} from 'react';
import {Canvas} from '@react-three/fiber';
import {Html, OrbitControls, useGLTF} from '@react-three/drei';
import {
    Box,
    Button,
    Container,
    Divider,
    Flex,
    Group,
    Loader,
    MantineProvider,
    Paper,
    Space,
    Stack,
    Stepper
} from "@mantine/core";
import {makeAuthenticatedRequest} from "../../Utils/authenticated_request";
import PatternBuilderSelect from "./PatternBuilderSelect";
import PatternBuilderDesign from "./PatternBuilderDesign";
import PatternBuilderMeasure from "./PatternBuilderMeasure";
import PatternBuilderSummary from "./PatternBuilderSummary";
import {UserContext} from "../../Context/User";

function Model({modelPath}) {
    const {scene} = useGLTF(modelPath);
    return <primitive object={scene}/>;
}

const PatternBuilderCreate = () => {
    const {user} = useContext(UserContext);

    const [items, setItems] = useState([])
    const [selectedItem, setSelectedItem] = useState(null);
    const [step, setStep] = useState(0);
    const [difficulty, setDifficulty] = useState(0);
    const [price, setPrice] = useState(0);
    const [activeDesignTab, setActiveDesignTab] = useState("");
    const [designTabs, setDesignTabs] = useState({})
    const [gaugeStitches, setGaugeStitches] = useState(1);
    const [gaugeRows, setGaugeRows] = useState(1);
    const [selectedStitch, setSelectedStitch] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null)


    const fetchPatternBuilderItems = async () => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/pattern_builder/`,
            );
            if (response.ok) {
                const jsonData = await response.json();
                setItems(jsonData.results)
                handleItemClick(jsonData.results[0])
            } else {
                console.error("Request failed with status:", response.status);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    useEffect(() => {
        fetchPatternBuilderItems()
    }, []);

    const nextStep = () => setStep((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setStep((current) => (current > 0 ? current - 1 : current));

    const handleItemClick = (item) => {
        setSelectedItem(item)
        setActiveDesignTab(item.parts.length !== 0 ? item.parts[0].name : "")
        const updatedDesignTabs = item.parts.reduce((acc, part) => {
                acc[part.name] = part.customisation_items ? part.customisation_items[0].name : "";
                return acc;
            },
            {}
        )
        setDesignTabs(updatedDesignTabs)
        setSelectedStitch(item.stitch_types[0])
        setSelectedSize(item.sizes[0])
        calculateDifficulty(item.difficulty, item.parts, updatedDesignTabs)
        calculatePrice(item.price, item.parts, updatedDesignTabs)
    }

    const calculateDifficulty = (baseDifficulty, parts, designTabs) => {
        let partsDifficulty = 0;

        for (const [partName, selectedItemName] of Object.entries(designTabs)) {
            const part = parts.find(p => p.name === partName);
            if (part) {
                const customisationItem = part.customisation_items.find(item => item.name === selectedItemName);
                if (customisationItem) {
                    partsDifficulty += customisationItem.difficulty;
                }
            }
        }
        setDifficulty(baseDifficulty + partsDifficulty)
    }

    const calculatePrice = (basePrice, parts, designTabs) => {
        let partsPrice = 0;

        for (const [partName, selectedItemName] of Object.entries(designTabs)) {
            const part = parts.find(p => p.name === partName);
            if (part) {
                const customisationItem = part.customisation_items.find(item => item.name === selectedItemName);
                if (customisationItem) {
                    partsPrice += Number(customisationItem.price);
                }
            }
        }
        setPrice(Number(basePrice) + partsPrice)
    }

    const updateDesignTabs = (key, value, part) => {
        const updatedDesignTabs = {...designTabs}
        updatedDesignTabs[key] = value
        setDesignTabs(updatedDesignTabs)
        calculateDifficulty(selectedItem.difficulty, selectedItem.parts, updatedDesignTabs)
        calculatePrice(selectedItem.price, selectedItem.parts, updatedDesignTabs)

    }

    const confirmPatternBuilder =  async () => {
        const customisationItemUuids = []
        for (const [partName, selectedItemName] of Object.entries(designTabs)) {
            const part = selectedItem.parts.find(p => p.name === partName);
            if (part) {
                const customisationItem = part.customisation_items.find(item => item.name === selectedItemName);
                if (customisationItem) {
                    customisationItemUuids.push(customisationItem.uuid)
                }
            }
        }
        const patternBuilderItem = {
            item_uuid: selectedItem.uuid,
            customisation_item_uuids: customisationItemUuids,
            stitch_type_uuid: selectedStitch.uuid,
            gauge_stitches: gaugeStitches,
            gauge_rows: gaugeRows,
            size_uuid: selectedSize.uuid,
        }

        // If the user is an admin, skip payment
        if (user.is_admin) {
            makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/generated_pattern/`,
                {
                    method: 'POST',
                    body: patternBuilderItem
                    }
            )
            return
        }

        const payload = {
            lookup_key: "PATTERN_BUILDER_ITEM",
            pattern_builder_item: patternBuilderItem,
        };

        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/pattern_builder/checkout/`,
                {method: 'POST',
                body: JSON.stringify(payload)}
            );
            if (response.ok) {
                const jsonData = await response.json();

                window.location.href = jsonData.checkout_url;
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <React.Fragment>
            <Space mt={"10vh"}/>
            <Box h={"90vh"} style={{display: 'flex', flexDirection: 'column', width: "100%"}}>
                <Container>
                    <Space h={"md"}/>
                    <Stepper active={step} onStepClick={setStep}>
                        <Stepper.Step label="Select" description="Choose your item">
                        </Stepper.Step>
                        <Stepper.Step label="Design" description="Customise your item">
                        </Stepper.Step>
                        <Stepper.Step label="Measure" description="Make your measurements">
                        </Stepper.Step>
                        <Stepper.Step label="Summary" description="Confirm your details">
                        </Stepper.Step>
                        <Stepper.Completed>
                            Completed, your selection has been confirmed
                        </Stepper.Completed>
                    </Stepper>
                    <Divider my={"md"}/>
                </Container>
                <Stack style={{flex: 1, overflowY: 'auto'}}>

                    <Flex
                        direction={{base: 'column', sm: 'row'}}
                        gap={{base: 'sm', sm: 'lg'}}
                        justify={{sm: 'center'}}
                    >
                            <Paper w="100%" shadow="xl" p="xl" m="md" withBorder>
                            {step === 0 &&
                                <PatternBuilderSelect
                                    items={items}
                                    handleItemClick={handleItemClick}
                                    selectedItem={selectedItem}
                                />
                            }
                            {step === 1 &&
                                <PatternBuilderDesign
                                    activeDesignTab={activeDesignTab}
                                    setActiveDesignTab={setActiveDesignTab}
                                    selectedItem={selectedItem}
                                    designTabs={designTabs}
                                    updateDesignTabs={updateDesignTabs}
                                    difficulty={difficulty}
                                />
                            }
                            {step === 2 &&
                                <PatternBuilderMeasure
                                    selectedStitch={selectedStitch}
                                    setSelectedStitch={setSelectedStitch}
                                    selectedItem={selectedItem}
                                    selectedSize={selectedSize}
                                    setSelectedSize={setSelectedSize}
                                    gaugeStitches={gaugeStitches}
                                    setGaugeStitches={setGaugeStitches}
                                    gaugeRows={gaugeRows}
                                    setGaugeRows={setGaugeRows}
                                />
                            }
                            {step === 3 &&
                                <PatternBuilderSummary
                                    designTabs={designTabs}
                                    selectedStitch={selectedStitch}
                                    selectedSize={selectedSize}
                                    gaugeStitches={gaugeStitches}
                                    gaugeRows={gaugeRows}
                                    price={price}
                                    difficulty={difficulty}
                                    confirmPatternBuilder={confirmPatternBuilder}
                                />
                            }
                        </Paper>
                        {step !== 0 && <Container miw="50%" m="md">
                            <Paper h="100%" shadow="xl" withBorder>
                                <Canvas camera={{position: [0, 0, -5], fov: 50}}>
                                    <ambientLight intensity={4.0}/>
                                    <directionalLight position={[0, 0, -5]} intensity={7.0}/>
                                    {/* Load item's base model if it exists */}
                                    {selectedItem?.model &&
                                        <Suspense
                                            fallback={
                                                <Html center>
                                                    <MantineProvider>
                                                        <Loader/>
                                                    </MantineProvider>
                                                </Html>
                                            }
                                        >
                                            <Model modelPath={selectedItem.model}/>
                                        </Suspense>
                                    }
                                    {/* Load models for the item's parts if they exist */}
                                    {selectedItem?.parts.map(part => {
                                        const selectedCustomisationItem = part.customisation_items.find(
                                            customisation_item => customisation_item.name === designTabs[part.name]
                                        );

                                        return (selectedCustomisationItem?.model ?
                                            <Suspense
                                                key={selectedCustomisationItem.uuid}
                                                fallback={
                                                    <Html center>
                                                        <MantineProvider>
                                                            <Loader/>
                                                        </MantineProvider>
                                                    </Html>
                                                }
                                            >
                                                <Model modelPath={selectedCustomisationItem.model}/>
                                            </Suspense> : null)
                                    })}
                                    <OrbitControls/>
                                </Canvas>
                            </Paper>
                        </Container>}
                    </Flex>
                </Stack>
                <Group justify="center" m="md">
                    <Button disabled={step === 0} variant="default" onClick={prevStep}>Back</Button>
                    <Button disabled={step === 3} onClick={nextStep}>Next step</Button>
                </Group>
            </Box>
        </React.Fragment>
    );
}

export default PatternBuilderCreate;
