import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { recordEvent } from "../services/pinpoint";

export default function PinpointTracker() {
  const location = useLocation();

  useEffect(() => {
    recordEvent("pageView", {
      path: location.pathname,
      referrer: document.referrer || "direct"
    });
  }, [location]);

  return null;
}