import React, {useContext, useState} from 'react';
import {
    ActionIcon,
    BackgroundImage,
    Blockquote,
    Button,
    Card,
    Center,
    Container,
    Flex,
    Group,
    Highlight,
    Image,
    List,
    Paper,
    rem,
    Space,
    Stack,
    Stepper,
    Text,
    ThemeIcon,
    Title,
    useMantineColorScheme,
    useMantineTheme,
} from "@mantine/core";
import {Link as RouterLink} from 'react-router-dom';
import Footer from "../Footer";
import {ParallaxProvider} from "react-scroll-parallax";
import SmallYarn from "../Shapes/smallYarn";
import MediumYarn from "../Shapes/mediumYarn";
import LargeYarn from "../Shapes/largeYarn";

import Feedback from "./Feedback";
import {
    IconAbacus,
    IconBrandInstagram,
    IconCaretDownFilled,
    IconCaretUpFilled,
    IconCircleCheck,
    IconEdit,
    IconFileExport,
    IconNeedleThread,
    IconSquaresFilled,
    IconTextWrap,
    IconUpload
} from "@tabler/icons-react";
import FeatureFrames from "./FeatureFrames";
import {UserContext} from "../../Context/User";
import {useMediaQuery} from "@mantine/hooks";
import mySvg from "../../Images/crochetDesign.svg";


const HomeShell = () => {
    const [alphaPatternStep, setAlphaPatternStep] = useState(0);
    const [symbolPatternStep, setSymbolPatternStep] = useState(0);
    const [rowCounterStep, setRowCounterStep] = useState(0);

    const theme = useMantineTheme()
    const {colorScheme} = useMantineColorScheme();

    const {user, loading} = useContext(UserContext);
    const isMobile = useMediaQuery('(max-width: 768px)');


    const nextAlphaPatternStep = () => setAlphaPatternStep((current) => (current < 3 ? current + 1 : current));
    const prevAlphaPatternStep = () => setAlphaPatternStep((current) => (current > 0 ? current - 1 : current));
    const nextSymbolPatternStep = () => setSymbolPatternStep((current) => (current < 2 ? current + 1 : current));
    const prevSymbolPatternStep = () => setSymbolPatternStep((current) => (current > 0 ? current - 1 : current));
    const nextRowCounterStep = () => setRowCounterStep((current) => (current < 3 ? current + 1 : current));
    const prevRowCounterStep = () => setRowCounterStep((current) => (current > 0 ? current - 1 : current));

    return (
        <>
            <Space h="10vh" />
            <ParallaxProvider>
                <SmallYarn/>
                <MediumYarn/>
                <LargeYarn/>
            </ParallaxProvider>
            <Flex
                mih={"90vh"}
                w="100%"
                direction={{sm: "row", base: "column"}}
                justify="center"
                align="center"
            >
                <Container p={0} m={0} mih={"100%"} style={{
                    backgroundImage: 'linear-gradient(45deg, var(--mantine-color-violet-4), var(--mantine-color-violet-9))',
                    flex: 1
                }}>
                    <Stack spacing="xl" c="white" p={"md"}>
                        {/*<Paper style={{        backgroundImage:*/}
                        {/*'linear-gradient(45deg, var(--mantine-color-violet-4), var(--mantine-color-violet-9))'}}>*/}
                        <Title align="center" order={1} size="h1" p={"xl"}>
                            Crochet Crafters
                        </Title>
                        {/*</Paper>*/}
                        {/*                                <Image*/}
                        {/*                      src={crochetLogo}*/}
                        {/*style={{*/}
  {/*  maxHeight: '20vh',*/}
  {/*  maxWidth: '100%',*/}
  {/*  height: 'auto',*/}
  {/*  width: 'auto',*/}
  {/*        objectFit: 'contain',*/}
  {/*    filter: whiteFilter*/}

  {/*}}*/}
  {/*                  ></Image>*/}

        <Text align="center" size="lg" p={"xl"} fw={500}>
          Start your next crochet masterpiece today!
        </Text>
          {!user && !loading && <>
              <Button variant="white" component={RouterLink} to="/sign-in">
                Sign In
            </Button>
            <Button variant="white" component={RouterLink} to="/sign-up">
                Sign Up
            </Button></>}
      </Stack>
                    </Container>
                            <Container style={{flex: 1}} h={"100%"}>
                                <Blockquote h={"100%"} color={"violet"}>
                                    <Paper shadow="xl">
                                        <Blockquote>
                                            <Text fw={500} size={"lg"}>
                                                <Highlight
                                                    ta="center"
                                                    highlight={['passionate crocheters', 'grid patterns', 'symbol designs', 'keeping track of rows']}
                                                    highlightStyles={{
                                                        backgroundImage:
                                                            'linear-gradient(45deg, var(--mantine-color-violet-4), var(--mantine-color-violet-9))',
                                                        fontWeight: 700,
                                                        WebkitBackgroundClip: 'text',
                                                        WebkitTextFillColor: 'transparent',
                                                    }}
                                                >
                                                    Unlock your creativity with our tools designed specifically for
                                                    passionate crocheters. Whether you're crafting personalized grid
                                                    patterns, intricate symbol designs, or just keeping track of rows,
                                                    we've got you covered.
                                                </Highlight>
                                            </Text>

                                        </Blockquote>
                                    </Paper>
                                    <List
                                        p={"xl"}
                                        spacing="md"
                                        size="md"
                                        center
                                        icon={
                                            <ThemeIcon color="violet" size={24} radius="xl">
                                                <IconCircleCheck style={{width: rem(16), height: rem(16)}}/>
                                            </ThemeIcon>
                                        }
                                    >
                                        <List.Item>
                                            <Paper shadow="xl" p="xs">
                                                <Text inline fw={1000} variant="gradient">
                                                    Grid Pattern Generator
                                                </Text>
                                                <Text fw={500} inline>Create and design grid patterns from uploaded
                                                    images</Text>
                                            </Paper>
                                        </List.Item>
                                        <List.Item>
                                            <Paper shadow="xl" p="xs">
                                                <Text inline fw={1000} variant="gradient">
                                                    Symbol Pattern Generator
                                                </Text>
                                                <Text fw={500} inline>Draw from our library of symbols to design your
                                                    own patterns</Text>
                                            </Paper>
                                        </List.Item>
                                        <List.Item>
                                            <Paper shadow="xl" p="xs">
                                                <Text inline fw={1000} variant="gradient">
                                                    Row Counter
                                                </Text>
                                                <Text fw={500} inline>Keep track of your progress on various
                                                    projects</Text>
                                            </Paper>
                                        </List.Item>
                                    </List>
                                </Blockquote>
                            </Container>
            </Flex>
            <BackgroundImage src={mySvg}
                             p={"xl"}
                             style={{height: "100%", borderImage: "fill 0 linear-gradient(#0003, #7048e8)"}}>
                <Center>
                    <Title style={{zIndex: 2}} c={"white"} p={"xs"}>Grid Pattern</Title>
                </Center>
            </BackgroundImage>
            <Flex
                w="100%"
                direction={{sm: "row", base: "column"}}
                justify="center"
                align="center"
            >
                <Card
                    withBorder
                    shadow="sm"
                    radius={0}
                    style={{
                        borderColor: theme.colors.violet[8],
                        boxShadow: `0 0 10px ${theme.colors.violet[8]}`,
                        flex: 1,
                        maxWidth: "50%",
                        width: "100%",
                    }}
                >
                    <Text size="md" c="dimmed">
                        There are many names for this type of pattern. Corner to corner (C2C),
                        graph pattern, pixel pattern, color chart, graphgan, grid pattern, tapestry,
                        mosaic, alpha pattern, the list goes on. What's important is that you can
                        create it right here!
                    </Text>
                    <List
                        p={"xl"}
                        spacing="md"
                        size="md"
                        center
                    >
                        <List.Item
                            icon={
                                <ThemeIcon color="violet" size={24} radius="xl">
                                    <IconUpload style={{width: rem(16), height: rem(16)}}/>
                                </ThemeIcon>
                            }
                        >
                            <Paper shadow="xl" p="xs">
                                <Text inline fw={1000} variant="gradient">
                                    Upload
                                </Text>
                                <Text fw={500} inline>Use an image or start from scratch</Text>
                            </Paper>
                        </List.Item>
                        <List.Item
                            icon={
                                <ThemeIcon color="violet" size={24} radius="xl">
                                    <IconEdit style={{width: rem(16), height: rem(16)}}/>
                                </ThemeIcon>
                            }
                        >
                            <Paper shadow="xl" p="xs">
                                <Text inline fw={1000} variant="gradient">
                                    Design
                                </Text>
                                <Text fw={500} inline>Customise each stitch colour to your choosing</Text>
                            </Paper>
                        </List.Item>
                        <List.Item
                            icon={
                                <ThemeIcon color="violet" size={24} radius="xl">
                                    <IconAbacus style={{width: rem(16), height: rem(16)}}/>
                                </ThemeIcon>
                            }
                        >
                            <Paper shadow="xl" p="xs">
                                <Text inline fw={1000} variant="gradient">
                                    Count
                                </Text>
                                <Text fw={500} inline>Keep track of your progress</Text>
                            </Paper>
                        </List.Item>
                        <List.Item
                            icon={
                                <ThemeIcon color="violet" size={24} radius="xl">
                                    <IconTextWrap style={{width: rem(16), height: rem(16)}}/>
                                </ThemeIcon>
                            }
                        >
                            <Paper shadow="xl" p="xs">
                                <Text inline fw={1000} variant="gradient">
                                    Export
                                </Text>
                                <Text fw={500} inline>Turn it into text to use on the go</Text>
                            </Paper>
                        </List.Item>
                    </List>
                    <Button m={"md"} component={RouterLink} to="/gridpattern">
                        Try it out yourself!
                    </Button>
                </Card>
                <Blockquote style={{
                    flex: 1,
                    maxWidth: "50%",
                    width: "100%",
                }}>
                    <Image
                        src={"alpha_pattern_render.png"}
                        alt="Alpha Pattern"
                    />
                </Blockquote>
            </Flex>
            <Space h="xl"/>
                        <BackgroundImage src={mySvg}
                             p={"xl"}
                             style={{height: "100%", borderImage: "fill 0 linear-gradient(#0003, #7048e8)"}}>
                <Center>
                    <Title style={{zIndex: 2}} c={"white"} p={"xs"}>Symbol Pattern</Title>
                </Center>
            </BackgroundImage>
            <Flex
                w="100%"
                direction={{sm: "row", base: "column"}}
                justify="center"
                align="center"
            >
                <Card
                    withBorder
                    shadow="sm"
                    radius={0}
                    style={{
                        borderColor: theme.colors.violet[8],
                        boxShadow: `0 0 10px ${theme.colors.violet[8]}`,
                        flex: 1,
                        maxWidth: "50%",
                        width: "100%",
                    }}
                >
                    <Text size="md" c="dimmed">
                        Also known as a symbol chart or crochet diagram,
                        you can create your very own by using our library of symbols!
                    </Text>
                    <List
                        p={"xl"}
                        spacing="md"
                        size="md"
                        center
                    >
                        <List.Item
                            icon={
                                <ThemeIcon color="violet" size={24} radius="xl">
                                    <IconNeedleThread style={{width: rem(16), height: rem(16)}}/>
                                </ThemeIcon>
                            }
                        >
                            <Paper shadow="xl" p="xs">
                                <Text inline fw={1000} variant="gradient">
                                    Design
                                </Text>
                                <Text fw={500} inline>Place symbols however you would like on the canvas</Text>
                            </Paper>
                        </List.Item>
                        <List.Item
                            icon={
                                <ThemeIcon color="violet" size={24} radius="xl">
                                    <IconSquaresFilled style={{width: rem(16), height: rem(16)}}/>
                                </ThemeIcon>
                            }
                        >
                            <Paper shadow="xl" p="xs">
                                <Text inline fw={1000} variant="gradient">
                                    Layer
                                </Text>
                                <Text fw={500} inline>Group your symbols into layers for easy management</Text>
                            </Paper>
                        </List.Item>
                        <List.Item
                            icon={
                                <ThemeIcon color="violet" size={24} radius="xl">
                                    <IconFileExport style={{width: rem(16), height: rem(16)}}/>
                                </ThemeIcon>
                            }
                        >
                            <Paper shadow="xl" p="xs">
                                <Text inline fw={1000} variant="gradient">
                                    Export
                                </Text>
                                <Text fw={500} inline>Create an image of your symbols to share with others</Text>
                            </Paper>
                        </List.Item>
                    </List>
                    <Button m={"md"} component={RouterLink} to="/symbolpattern">
                        Try it out yourself!
                    </Button>
                </Card>
                <Blockquote style={{
                    flex: 1,
                    maxWidth: "50%",
                    width: "100%",
                }}>
                    <Image
                        src={"symbol_pattern_render.png"}
                        alt="Symbol Pattern"
                    />
                </Blockquote>
            </Flex>

                        <Space h="xl"/>
                        <BackgroundImage src={mySvg}
                             p={"xl"}
                             style={{height: "100%", borderImage: "fill 0 linear-gradient(#0003, #7048e8)"}}>
                <Center>
                    <Title style={{zIndex: 2}} c={"white"} p={"xs"}>Row Counter</Title>
                </Center>
            </BackgroundImage>
            <Flex
                w="100%"
                direction={{sm: "row", base: "column"}}
                justify="center"
                align="center"
            >
                <Card
                    withBorder
                    shadow="sm"
                    radius={0}
                    style={{
                        borderColor: theme.colors.violet[8],
                        boxShadow: `0 0 10px ${theme.colors.violet[8]}`,
                        flex: 1,
                        maxWidth: "50%",
                        width: "100%",
                    }}
                >
                    <Text size="md" c="dimmed">
                        Keep track of your project progress with the row counter!
                    </Text>
                    <List
                        p={"xl"}
                        spacing="md"
                        size="md"
                        center
                    >
                        <List.Item
                            icon={
                                <ThemeIcon color="violet" size={24} radius="xl">
                                    <IconAbacus style={{width: rem(16), height: rem(16)}}/>
                                </ThemeIcon>
                            }
                        >
                            <Paper shadow="xl" p="xs">
                                <Text inline fw={1000} variant="gradient">
                                    Count
                                </Text>
                                <Text fw={500} inline>Count your rows and stitches</Text>
                            </Paper>
                        </List.Item>
                    </List>
                    <Button m={"md"} component={RouterLink} to="/rowcounter">
                        Try it out yourself!
                    </Button>
                </Card>
                <Blockquote style={{
                    flex: 1,
                    maxWidth: "50%",
                    width: "100%",
                }}>
                    <Image
                        src={"row_counter_render.png"}
                        alt="Row Counter"
                    />
                </Blockquote>
            </Flex>
                                    <Space h="xl"/>

            <Stack
                gap={"100px"}
                mt={"100px"}
                style={{
                    marginTop: '100px',
                    margin: 'auto',
                    width: isMobile ? "100%" : "70%"
                }}
            >
            <Feedback/>
                    <Button
                        onClick={
                        () => window.open(
                            "https://instagram.com/officialcrochetcrafters",
                            "_blank",
                            "noopener,noreferrer"
                        )
                    }
                        justify="center"
                        fullWidth
                        leftSection={<IconBrandInstagram />}
                        variant="default"
                        mt="md"
                    >
                        Follow us on Instagram!
                    </Button>
                    <Center>
                    <Group spacing={"xs"}>
                    <Button size="xs" variant="subtle" component={RouterLink} to="/privacy">
                        Privacy Policy
                    </Button>
                    <Button size="xs" variant="subtle" component={RouterLink} to="/terms">
                        Terms
                    </Button>
                        </Group>
                        </Center>
                </Stack>
                        <Space h="5vh" />

            <Footer/>
        </>
    );
};

export default HomeShell;
