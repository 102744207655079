import React, {useContext} from 'react';
import {
    ActionIcon,
    Card,
    Center,
    Grid,
    Group,
    Image,
    SemiCircleProgress,
    Text,
    Tooltip,
    useMantineTheme
} from "@mantine/core";
import {Link as RouterLink} from "react-router-dom";
import {IconRosette} from "@tabler/icons-react";
import {UserContext} from "../../Context/User";


const PatternBuilderSelect = (
    {
        items,
        handleItemClick,
        selectedItem,
    }
    ) => {
    const {user} = useContext(UserContext);
    const theme = useMantineTheme();


    return (
        <React.Fragment>
<Grid justify="flex-start" align="start">

                                {items.filter(item => user.is_admin || item.is_released).map((item) => {
                                        return (
                                            <Grid.Col key={item.uuid} span={{xl: 3, lg: 4, md: 6, sm: 12}}>

                                                <Card
                                                    withBorder
                                                    shadow="sm"
                                                    radius="md"
                                                    onClick={() => handleItemClick(item)}
                                                    style={{
                                                        cursor: "pointer",
                                                        ...(selectedItem === item && {
                                                            borderColor: theme.colors.violet[8], // Apply custom border color when selected
                                                            boxShadow: `0 0 10px ${theme.colors.violet[8]}`, // Apply custom shadow when selected
                                                        }),
                                                    }}
                                                >
                                                    <Card.Section withBorder inheritPadding py="xs">
                                                        <Group justify="space-between">
                                                            <Text fw={500}>{item.name}</Text>
                                                            {item.is_premium &&
                                                                <Tooltip label="Premium Required">
                                                                    <ActionIcon component={RouterLink} to="/premium"
                                                                                size="lg">
                                                                        <IconRosette size={20}/>
                                                                    </ActionIcon>
                                                                </Tooltip>
                                                            }
                                                        </Group>
                                                    </Card.Section>

                                                    <Text mt="sm" c="dimmed" size="sm">
                                                        {item.description}
                                                    </Text>

                                                    <Card.Section mt="sm">
                                                        <Image src={item.thumbnail}/>
                                                    </Card.Section>
                                                    <Center>
                                                        <SemiCircleProgress
                                                            fillDirection="left-to-right"
                                                            orientation="up"
                                                            size={200}
                                                            thickness={20}
                                                            value={item.difficulty}
                                                            label="Difficulty"
                                                        />
                                                    </Center>
                                                </Card>
                                            </Grid.Col>
                                        )
                                    }
                                )}
                            </Grid>
        </React.Fragment>
    );
}

export default PatternBuilderSelect;
