// src/services/pinpoint.js
import { PinpointClient, PutEventsCommand } from "@aws-sdk/client-pinpoint";

const REGION = "us-east-1";
const APP_ID = "506cf912ffd947d0a52fd8a2c9fa341f";

const pinpointClient = new PinpointClient({
  region: REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  }
});

export const recordEvent = async (eventName, attributes = {}) => {
  const params = {
    ApplicationId: APP_ID,
    EventsRequest: {
      BatchItem: {
        [Date.now().toString()]: { // Unique event ID
          Endpoint: {
            Attributes: attributes,
            ChannelType: "WEB" // For web app
          },
          Events: {
            [eventName]: {
              EventType: eventName,
              Timestamp: new Date().toISOString()
            }
          }
        }
      }
    }
  };

  try {
    await pinpointClient.send(new PutEventsCommand(params));
  } catch (error) {
    console.error("Pinpoint error:", error);
  }
};