import React from 'react';
import {
    ActionIcon,
    Anchor,
    Blockquote,
    Divider,
    Highlight,
    NativeSelect,
    NumberInput,
    Paper,
    Popover,
    Space,
    Table,
    Tooltip
} from "@mantine/core";
import {IconHelp, IconInfoCircle} from "@tabler/icons-react";


const PatternBuilderMeasure = (
    {
        selectedStitch,
        setSelectedStitch,
        selectedItem,
        selectedSize,
        setSelectedSize,
        gaugeStitches,
        setGaugeStitches,
        gaugeRows,
        setGaugeRows,
    }
) => {

    return (
<React.Fragment>
                                <Paper shadow="xl">
                                    <Blockquote icon={<IconInfoCircle/>} color={"violet"}>
                                        It's time to choose your stitch, size, and make your custom measurements by creating a
                                        gauge swatch.
                                        Don't know what a gauge swatch is, or how to create it?
                                        Check <Anchor href="/tutorials/gauge-swatch" target="_blank"> this small
                                        tutorial out </Anchor> to learn more!
                                    </Blockquote>
                                </Paper>
                                <Divider my={"md"}/>
                                {/*<NativeSelect*/}
                                {/*    label="Stitch"*/}
                                {/*    description="Select your stitch type"*/}
                                {/*    value={selectedStitch.name}*/}
                                {/*    onChange={(event) => setSelectedStitch(selectedItem.stitch_types.find(stitch => stitch.name === event.currentTarget.value))}*/}
                                {/*    data={selectedItem.stitch_types.map(stitch => stitch.name)}*/}
                                {/*/>*/}
                                {/*<Divider my={"md"}/>*/}
                                <NativeSelect
                                    label="Size"
                                    description="Select your size"
                                    value={selectedSize.size}
                                    onChange={(event) => setSelectedSize(selectedItem.sizes.find(size => size.size === event.currentTarget.value))}
                                    data={selectedItem.sizes.map(size => size.size)}
                                />
                                <Space h={"md"}/>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                <Popover position="bottom" withArrow shadow="md">
                                  <Popover.Target>
                                      <Tooltip label="Size Chart">

                                      <ActionIcon
                                          variant="outline"
                                      aria-label="Size Chart"
                                    >
                                      <IconHelp />
                                    </ActionIcon>
                                      </Tooltip>
                                  </Popover.Target>
                                  <Popover.Dropdown>
                                    <Table>
                                      <Table.Thead>
                                        <Table.Tr>
                                          <Table.Th>Size</Table.Th>
                                          {Object.keys(selectedSize.measurements).map(measurement => (
                                            <Table.Th key={measurement}>{measurement}</Table.Th>
                                          ))}
                                        </Table.Tr>
                                      </Table.Thead>
                                      <Table.Tbody>
                                        {selectedItem.sizes.map(size => (
                                          <Table.Tr key={size.size}>
                                            <Table.Td>{size.size}</Table.Td>
                                            {Object.values(size.measurements).map((measurement, index) => (
                                              <Table.Td key={index}>{measurement}</Table.Td>
                                            ))}
                                          </Table.Tr>
                                        ))}
                                      </Table.Tbody>
                                    </Table>

                                  </Popover.Dropdown>
                                </Popover>
                                </div>
                                <Divider my={"md"}/>


                                <Highlight
                                    ta="center"
                                    highlight={['gauge swatch', '5', '4', '12.5', '10'].concat(selectedItem.stitch_types.map(stitch => stitch.name))}
                                    highlightStyles={{
                                        backgroundImage:
                                            'linear-gradient(45deg, var(--mantine-color-violet-4), var(--mantine-color-violet-9))',
                                        fontWeight: 700,
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                    }}
                                >
                                    {`Please create a gauge swatch with the length of 5 inches (12.5 centimetres) made out of ${selectedStitch.name}, then measure the inner 4 inches (10 centimetres).`}
                                </Highlight>
                                <NumberInput
                                    label="Stitches"
                                    value={gaugeStitches}
                                    onChange={setGaugeStitches}
                                    allowNegative={false}
                                    clampBehavior="strict"
                                    min={1}
                                />
                                <NumberInput
                                    label="Rows"
                                    value={gaugeRows}
                                    onChange={setGaugeRows}
                                    allowNegative={false}
                                    clampBehavior="strict"
                                    min={1}
                                />
                            </React.Fragment>
    );
}

export default PatternBuilderMeasure;
