import React, {useContext, useEffect, useRef, useState} from "react";
import {makeAuthenticatedRequest} from "../../Utils/authenticated_request";
import {
    ActionIcon,
    Alert,
    AspectRatio,
    Button,
    Card,
    Center,
    Container,
    Flex,
    Image,
    Overlay,
    Paper,
    rem,
    ScrollArea,
    SimpleGrid,
    Space,
    Text,
    TextInput,
    Tooltip,
} from '@mantine/core';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {IconInfoCircle, IconLock, IconPlus, IconSearch, IconX} from "@tabler/icons-react";
import {UserContext} from "../../Context/User";
import Footer from "../Footer";
import {ParallaxProvider} from "react-scroll-parallax";
import SmallYarn from "../Shapes/smallYarn";
import MediumYarn from "../Shapes/mediumYarn";
import LargeYarn from "../Shapes/largeYarn";


const SymbolPatternManager = (
    {}) => {
    const navigate = useNavigate();
    const {user} = useContext(UserContext);
    const canvasRef = useRef(null);

    const [symbolPatterns, setSymbolPatterns] = React.useState([])
    const [symbolPatternSearch, setSymbolPatternSearch] = useState('');
    const [displayedSymbolPatterns, setDisplayedSymbolPatterns] = useState(symbolPatterns);

    const fetchSymbolPatterns = async () => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/symbol_pattern/`,
                {redirect: false}
            );

            if (response.ok) {
                const jsonData = await response.json();
                setSymbolPatterns(jsonData.results)
                setDisplayedSymbolPatterns(jsonData.results)
            } else {
                console.error("Request failed with status:", response.status);
                setSymbolPatterns([])
                setDisplayedSymbolPatterns([])
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const deleteSymbolPattern = async (symbolPattern) => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/symbol_pattern/${symbolPattern.uuid}/`,
                {method: 'DELETE'},
            );

            if (response.ok) {
                await fetchSymbolPatterns()
            } else {
                console.error("Request failed with status:", response.status);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const editSymbolPattern = (symbolPattern, symbolPatternIndex) => {
        if (symbolPatternIndex >= 100) {
            return
        }
        const image = new window.Image();
        image.crossOrigin = "Anonymous";  // Set the crossOrigin attribute to handle CORS
        image.src = symbolPattern.file;
        image.onload = () => {
            navigate('/symbolpattern/edit/', {
                state: {
                    canvasSymbols: symbolPattern.data.canvasSymbols,
                    layers: symbolPattern.data.layers,
                    name: symbolPattern.name,
                    uuid: symbolPattern.uuid
                }
            });
        };
    }

    const handleDeleteSearch = () => {
        setSymbolPatternSearch("")
        setDisplayedSymbolPatterns(symbolPatterns)
    }

    const handleSymbolPatternSearch = (event) => {
        setSymbolPatternSearch(event.currentTarget.value)


        const filteredSymbolPatterns = symbolPatterns.filter((symbolPattern) =>
            symbolPattern.name.toLowerCase().includes(event.currentTarget.value.toLowerCase())
        );

        if (filteredSymbolPatterns !== displayedSymbolPatterns) {
            setDisplayedSymbolPatterns(filteredSymbolPatterns);
        }
    };

    useEffect(() => {
        fetchSymbolPatterns()
    }, []);

    return (
        <React.Fragment>
            <canvas ref={canvasRef} style={{display: 'none'}}/>
            <Space mt={"10vh"}/>
            <ParallaxProvider>
                <SmallYarn/>
                <MediumYarn/>
                <LargeYarn/>
            </ParallaxProvider>
            <Container size={"80%"}>
                {symbolPatterns.length >= 100 ? (<Tooltip
                    label={"Max 100 allowed, please delete some items"}
                >
                    <Button p={20} disabled fullWidth size="xl" leftSection={<IconPlus size={20}/>}>
                        Create New
                    </Button>
                </Tooltip>) : (<Button p={20} disabled={symbolPatterns.length > 100} fullWidth size="xl"
                                       leftSection={<IconPlus size={20}/>}
                                       component={RouterLink}
                                       to="create">Create New</Button>)}
                        <TextInput
                            mt={"md"}
                            leftSection={<IconSearch style={{width: rem(16), height: rem(16)}}/>}
                            leftSectionPointerEvents="none"
                            rightSection={symbolPatternSearch ? <IconX onClick={handleDeleteSearch} style={{
                                width: rem(16),
                                height: rem(16)
                            }}/> : null}
                            aria-label="Search Symbol Patterns"
                            value={symbolPatternSearch}
                            onChange={(event) => handleSymbolPatternSearch(event)}
                        />
                {user?.tier !== "PREMIUM" &&
                    <Alert title="Premium required to save" icon={<IconInfoCircle/>}>
                        You're not signed in as a premium member. You can create symbol patterns, but
                        you won't be able to save any pattern that you create.
                    </Alert>
                }
                <Paper shadow="xl" p="xl" m="md" withBorder>
                    <ScrollArea h={'55vh'}>
                        <SimpleGrid
                            cols={{base: 1, sm: 2, md: 3, lg: 4}}
                            spacing={{base: 10, sm: 'xl'}}
                            verticalSpacing={{base: 'md', sm: 'xl'}}
                        >
                            {displayedSymbolPatterns.map((symbolPattern, symbolPatternIndex) => {
                                return (
                                    <Card
                                        key={symbolPattern.uuid}
                                        shadow="sm"
                                        padding="lg"
                                        radius="md"
                                        withBorder
                                        onClick={() => editSymbolPattern(symbolPattern, symbolPatternIndex)}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                    >
                                        <Flex
                                            justify="flex-end"
                                            align="flex-start"
                                            direction="row"
                                            wrap="wrap"
                                        >
                                            <Tooltip label="Delete Symbol Pattern">
                                                <ActionIcon
                                                    variant="outline"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        deleteSymbolPattern(symbolPattern)
                                                    }}
                                                >
                                                    <IconX size={20}/>
                                                </ActionIcon>
                                            </Tooltip>
                                        </Flex>
                                        <Card.Section p={"md"}>
                                            <AspectRatio ratio={16 / 9} mx="auto" pos="relative">
                                                <Image
                                                    radius="md"
                                                    src={symbolPattern.file}
                                                    crossOrigin="Anonymous"
                                                />
                                                {(symbolPatternIndex >= 100) &&
                                                    <Overlay color="#000" backgroundOpacity={0.85}/>}
                                            </AspectRatio>
                                        </Card.Section>
                                        <Center>
                                            <Text
                                                size="xl"
                                                fw={900}
                                                variant={"gradient"}
                                                truncate="end"
                                            >
                                                {(symbolPatternIndex >= 100) &&
                                                    <Tooltip
                                                        label={"Max 100 allowed, please delete some items"}><IconLock/></Tooltip>}
                                                {symbolPattern.name}
                                            </Text>
                                        </Center>
                                    </Card>
                                )
                            })}
                        </SimpleGrid>
                    </ScrollArea>
                </Paper>
            </Container>
            <Footer/>
        </React.Fragment>
    )

}

export default SymbolPatternManager;