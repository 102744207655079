import React, {useContext, useEffect} from "react";
import {makeAuthenticatedRequest} from "../../Utils/authenticated_request";
import {
    Alert,
    Badge,
    Button,
    Card,
    Center,
    Container,
    Divider,
    Image,
    Paper,
    ScrollArea,
    SimpleGrid,
    Space,
    Title,
    useMantineTheme,
} from '@mantine/core';
import {Link as RouterLink} from "react-router-dom";
import {IconInfoCircle, IconPlus} from "@tabler/icons-react";
import {UserContext} from "../../Context/User";
import Footer from "../Footer";
import {ParallaxProvider} from "react-scroll-parallax";
import SmallYarn from "../Shapes/smallYarn";
import MediumYarn from "../Shapes/mediumYarn";
import LargeYarn from "../Shapes/largeYarn";


const PatternBuilderManager = (
    {}) => {
    const {user} = useContext(UserContext);
    const theme = useMantineTheme();

    const [generatedPatterns, setGeneratedPatterns] = React.useState([])

    const fetchGeneratedPatterns = async () => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/generated_pattern/`,
                {redirect: false}
            );

            if (response.ok) {
                const jsonData = await response.json();
                setGeneratedPatterns(jsonData.results)
            } else {
                console.error("Request failed with status:", response.status);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }


    useEffect(() => {
        fetchGeneratedPatterns()
    }, []);

    return (
        <React.Fragment>
            <Space mt={"10vh"}/>
            <ParallaxProvider>
                <SmallYarn/>
                <MediumYarn/>
                <LargeYarn/>
            </ParallaxProvider>
            <Container size={"80%"}>

                <Button
                    p={20}
                    fullWidth
                    size="xl"
                    leftSection={<IconPlus size={20}/>}
                    component={RouterLink}
                    to="create">
                    Create New
                </Button>
                {!user &&
                    <Alert title="Account required to save" icon={<IconInfoCircle/>}>
                        You're not signed in. You can look around, but you won't be able to create any patterns.
                    </Alert>
                }
                <Paper shadow="xl" p="xl" m="md" withBorder>
                    <ScrollArea h={'65vh'}>
                        <SimpleGrid
                            cols={{base: 1, sm: 2, md: 3, lg: 4}}
                            spacing={{base: 10, sm: 'xl'}}
                            verticalSpacing={{base: 'md', sm: 'xl'}}
                        >
                            {generatedPatterns.map((generatedPattern) => {
                                return (
                                    <Card
                                        key={generatedPattern.uuid}
                                        shadow="sm"
                                        padding="xs"
                                        radius="md"
                                        withBorder
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => window.open(generatedPattern.pdf, "_blank")}
                                    >
                                        <Center>
                                            <Title fw={500} order={4}>
                                                {generatedPattern.item.name}
                                            </Title>
                                        </Center>
                                        <Divider my={"md"}/>

                                        <Card.Section mt="sm">
                                            <Image src={generatedPattern.item.thumbnail}/>
                                        </Card.Section>
                                        <Card.Section p={"md"}>
                                            <Divider my={"md"}/>
                                            {generatedPattern.customisation_items.map((customisation_item) =>
                                                <Badge>{customisation_item.name}</Badge>
                                            )}
                                        </Card.Section>
                                    </Card>
                                )
                            })}
                        </SimpleGrid>
                    </ScrollArea>
                </Paper>
            </Container>
            <Footer/>
        </React.Fragment>
    )

}

export default PatternBuilderManager;