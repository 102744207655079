import React, {useContext, useEffect, useRef, useState} from "react";
import {makeAuthenticatedRequest} from "../../Utils/authenticated_request";
import {
    ActionIcon,
    Alert,
    AspectRatio,
    Button,
    Card,
    Center,
    Container,
    Flex,
    Image,
    Overlay,
    Paper,
    rem,
    ScrollArea,
    SimpleGrid,
    Space,
    Text,
    TextInput,
    Tooltip,
} from '@mantine/core';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {createCell} from "../../Utils/cell";
import {IconInfoCircle, IconLock, IconPlus, IconSearch, IconX} from "@tabler/icons-react";
import {UserContext} from "../../Context/User";
import Footer from "../Footer";
import {ParallaxProvider} from "react-scroll-parallax";
import SmallYarn from "../Shapes/smallYarn";
import MediumYarn from "../Shapes/mediumYarn";
import LargeYarn from "../Shapes/largeYarn";


const AlphaPatternManager = (
    {}) => {
    const navigate = useNavigate();
    const {user} = useContext(UserContext);
    const canvasRef = useRef(null);

    const [alphaPatterns, setAlphaPatterns] = React.useState([])
    const [alphaPatternSearch, setAlphaPatternSearch] = useState('');
    const [displayedAlphaPatterns, setDisplayedAlphaPatterns] = useState(alphaPatterns);

    const fetchAlphaPatterns = async () => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/alpha_pattern/`,
                {redirect: false}
            );

            if (response.ok) {
                const jsonData = await response.json();
                setAlphaPatterns(jsonData.results)
                setDisplayedAlphaPatterns(jsonData.results)
            } else {
                console.error("Request failed with status:", response.status);
                setAlphaPatterns([])
                setDisplayedAlphaPatterns([])
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const deleteAlphaPattern = async (alphaPattern) => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/alpha_pattern/${alphaPattern.uuid}/`,
                {method: 'DELETE'},
            );

            if (response.ok) {
                await fetchAlphaPatterns()
            } else {
                console.error("Request failed with status:", response.status);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const editAlphaPattern = (alphaPattern, alphaPatternIndex) => {
        if (alphaPatternIndex >= 100) {
            return
        }
        const image = new window.Image();
        image.crossOrigin = "Anonymous";  // Set the crossOrigin attribute to handle CORS
        image.src = alphaPattern.file;
        image.onload = () => {
            const gridData = processImage(image);
            navigate('/gridpattern/edit/', {
                state: {
                    imageGridData: gridData,
                    name: alphaPattern.name,
                    uuid: alphaPattern.uuid,
                    rowDirection: alphaPattern.row_direction,
                    rowStarting: alphaPattern.row_starting,
                    rowClockwise: alphaPattern.row_clockwise,
                    rowSwitching: alphaPattern.row_switching,
                    currentRow: alphaPattern.current_row,
                    currentCell: alphaPattern.current_cell,
                    colorPalette: alphaPattern.color_palette,
                }
            });
        };
    }

    const processImage = (image) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        const imageData = ctx.getImageData(0, 0, image.width, image.height);
        const data = imageData.data;

        const matrix = [];
        for (let y = 0; y < image.height; y++) {
          const row = [];
          for (let x = 0; x < image.width; x++) {
            const index = (y * image.width + x) * 4;
            const r = data[index];
            const g = data[index + 1];
            const b = data[index + 2];
            const color = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
            row.push(createCell(x, y, color));
          }
          matrix.push(row);
        }
        return matrix
    };

    const handleDeleteSearch = () => {
        setAlphaPatternSearch("")
        setDisplayedAlphaPatterns(alphaPatterns)
    }

    const handleAlphaPatternSearch = (event) => {
        setAlphaPatternSearch(event.currentTarget.value)


        const filteredAlphaPatterns = alphaPatterns.filter((alphaPattern) =>
            alphaPattern.name.toLowerCase().includes(event.currentTarget.value.toLowerCase())
        );

        if (filteredAlphaPatterns !== displayedAlphaPatterns) {
            setDisplayedAlphaPatterns(filteredAlphaPatterns);
        }
    };

    useEffect(() => {
        fetchAlphaPatterns()
    }, []);

    return (
        <React.Fragment>
            <canvas ref={canvasRef} style={{display: 'none'}}/>
            <Space mt={"10vh"}/>
            <ParallaxProvider>
                <SmallYarn/>
                <MediumYarn/>
                <LargeYarn/>
            </ParallaxProvider>
            <Container size={"80%"}>

                {alphaPatterns.length >= 100 ? (<Tooltip
                    label={"Max 100 allowed, please delete some items"}
                >
                    <Button p={20} disabled fullWidth size="xl" leftSection={<IconPlus size={20}/>}>
                        Create New
                    </Button>
                </Tooltip>) : (<Button p={20} disabled={alphaPatterns.length >= 100} fullWidth size="xl"
                                       leftSection={<IconPlus size={20}/>}
                                       component={RouterLink}
                                       to="create">Create New</Button>)}
                <TextInput
                    mt={"md"}
                    leftSection={<IconSearch style={{width: rem(16), height: rem(16)}}/>}
                    leftSectionPointerEvents="none"
                    rightSection={alphaPatternSearch ? <IconX onClick={handleDeleteSearch} style={{
                        width: rem(16),
                        height: rem(16)
                    }}/> : null}
                    aria-label="Search Grid Patterns"
                    value={alphaPatternSearch}
                    onChange={(event) => handleAlphaPatternSearch(event)}
                />
                {user?.tier !== "PREMIUM" &&
                    <Alert title="Premium required to save" icon={<IconInfoCircle/>}>
                        You're not signed in as a premium member. You can create grid patterns, but you won't be able to
                        save any pattern that you create.
                    </Alert>
                }
                <Paper shadow="xl" p="xl" m="md" withBorder>
                    <ScrollArea h={'55vh'}>
                        <SimpleGrid
                            cols={{base: 1, sm: 2, md: 3, lg: 4}}
                            spacing={{base: 10, sm: 'xl'}}
                            verticalSpacing={{base: 'md', sm: 'xl'}}
                        >
                            {displayedAlphaPatterns.map((alphaPattern, alphaPatternIndex) => {
                                return (
                                    <Card
                                        key={alphaPattern.uuid}
                                        shadow="sm"
                                        padding="lg"
                                        radius="md"
                                        withBorder
                                        onClick={() => editAlphaPattern(alphaPattern, alphaPatternIndex)}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                    >
                                        <Flex
                                            justify="flex-end"
                                            align="flex-start"
                                            direction="row"
                                            wrap="wrap"
                                        >
                                            <Tooltip label="Delete Grid Pattern">
                                                <ActionIcon
                                                    variant="outline"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        deleteAlphaPattern(alphaPattern)
                                                    }}
                                                >
                                                    <IconX size={20}/>
                                                </ActionIcon>
                                            </Tooltip>
                                        </Flex>
                                        <Card.Section p={"md"}>
                                            <AspectRatio ratio={16 / 9} mx="auto" pos="relative">
                                                <Image
                                                    radius="md"
                                                    src={alphaPattern.file}
                                                    crossOrigin="Anonymous"
                                                />
                                                {(alphaPatternIndex >= 100) &&
                                                    <Overlay color="#000" backgroundOpacity={0.85}/>}
                                            </AspectRatio>
                                        </Card.Section>
                                        <Center>
                                            <Text
                                                size="xl"
                                                fw={900}
                                                variant={"gradient"}
                                                truncate="end"
                                            >
                                                {(alphaPatternIndex >= 100) &&
                                                    <Tooltip
                                                        label={"Max 100 allowed, please delete some items"}><IconLock/>
                                                    </Tooltip>
                                                }
                                                {alphaPattern.name}
                                            </Text>
                                        </Center>
                                    </Card>
                                )
                            })}
                        </SimpleGrid>
                    </ScrollArea>
                </Paper>
            </Container>
            <Footer/>
        </React.Fragment>
    )

}

export default AlphaPatternManager;